import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import AmberScore from "../components/reuseable/AmberScore";
import MeetAmber from "../components/homepage/MeetAmber";
import ClientsHome from '../components/homepage/ClientsHome';
import VideoCardHome from '../components/homepage/VideoCardHome';
import ThreeSteps from '../components/homepage/ThreeSteps';
import HomeStories from '../components/homepage/HomeStories';
import ScaleSecurity from '../components/homepage/ScaleSecurity';

const IndexPage = () => (
  <Layout>
    <SEO title="Amber by inFeedo&#8482; | Employee Experience Platform" description="Amber is an AI chatbot that talks to your employees to help you identify those who are unhappy, disengaged or about to leave. Join 100+ CHROs who have tossed their employee experience playbooks to take the first step to becoming an employee-first leader."/>
    <MeetAmber/>
    <ClientsHome/>
    <VideoCardHome/>
    <ThreeSteps/>
    <HomeStories/>
    <ScaleSecurity/>
    <AmberScore/>
  </Layout>
)

export default IndexPage
