import React from "react";
import { Link } from "gatsby";
import DesktopLogos from '../../images/home-logos-desktop.png'
import MobileLogos from '../../images/home-logos-mobile.png'

const ClientsHome = () => (
    <div className="has-text-centered bg-clients-home columns flex-wrap no-mg-lr">
        <h3 className="column mg-bottom-30 is-12 is-size-3-tablet is-size-4-mobile has-text-weight-bold">100+ enterprises across 50+ countries trust her.</h3>
        <div className="column is-11 mar-auto">
            <img src={DesktopLogos} width="100%" className="is-hidden-mobile" alt="client logos"/>
            <img src={MobileLogos} width="100%" className="is-hidden-tablet" alt="client logos"/>
        </div>
        <div className="column is-12">
            <br/>
            <Link to="/customers" className="button is-link is-inverted transparent-bg is-blue-text">
                See  more customers <i className="arrow right right-arrow"></i>
            </Link>
        </div>
    </div>
)

export default ClientsHome

