import React from "react";
import Quotes from '../../images/quotes.png'

const VideoCardHome = () => (
    <div className="has-text-centered bg-path-2 columns flex-wrap no-mg-lr video-card-home">
        <div className="column is-11 is-10-mobile box mar-auto box-home">
            <div className="columns">
                <div className="column is-6 height-home-video is-paddingless">
                    <div className="w-embed-youtubevideo youtube-2"><iframe title="video" src="https://www.youtube.com/embed/OJJCBc2IoVs?rel=0&amp;controls=1&amp;autoplay=0&amp;mute=0&amp;start=0" frameBorder="0" className="iframe-video" allow="autoplay; encrypted-media" allowFullScreen=""></iframe></div>
                </div>
                <div className="column is-6 has-text-left pd-30">
                    <br/>
                    <img src={Quotes} width="30px" alt="quotes"/>
                    <h3 className="is-size-4-tablet is-size-5-mobile has-text-weight-bold">Even if I tripled our number of HRBPs & they spent 4x the hours with each employee, that kind of insight would never have come.</h3>
                    <br/>
                    <p>Leaders from Deloitte, Accenture, MakeMyTrip, Nestle, Airtel share takeaways from employee experience chatbots in Asia’s largest HR Tech conference.</p>
                    <br/>
                </div>
            </div>
        </div>
        <div className="column is-7 mar-auto is-size-5 has-text-centered">
            <p className="pd-30">According to Gartner, organisations are moving away from annual engagement feedback surveys after realising they're not enough to measure employee experience.</p>
            <br/>
            <a href="https://blog.infeedo.com/employee-engagement-strategy-relevant-2020">
                Learn More <i className="arrow right right-arrow"></i>
            </a>
        </div>
    </div>
)

export default VideoCardHome

