import React from "react";
import hrms_logo from '../../images/hrms-logos.png';
import tick from '../../images/tick.png';

const ScaleSecurity = () => (
    <div className="has-text-centered pd-10">
        <div className="is-hidden-tablet">
            <br/>
            <br/>
        </div>
        <h3 className="is-size-3-tablet is-size-4-mobile has-text-weight-bold pd-30">Built with scale and security in mind</h3>
        <br/>
        <div className="columns column is-11 mar-auto">
            <div className="column is-4 has-text-left">
                <h3 className="has-text-weight-bold">Integrates directly with your internal systems</h3><br/>
                <p>Amber can plug into all leading HRMS & communication tools in the market making implementation for you seamless and <b>zero-effort.</b></p>
                <br/>
                <img src={hrms_logo} alt="hrms"/>
            </div>
            <div className="column is-7 is-offset-1 has-text-left">
                <h3 className="has-text-weight-bold">Security & Compliances</h3><br/>
                <div className="columns">
                    <div className="column is-6">
                        <p>External Checks & Compliances:</p>
                        <br/>
                        <p className="font-14 mg-bottom-5"><img src={tick} width="15px" alt="tick"/> &nbsp;GDPR Compliant</p>
                        <p className="font-14 mg-bottom-5"><img src={tick} width="15px" alt="tick"/> &nbsp;ISO 27001:2013 Certified</p>
                        <p className="font-14 mg-bottom-5"><img src={tick} width="15px" alt="tick"/> &nbsp;Bi-annual VAPT security audit</p>
                        <p className="font-14 mg-bottom-5"><img src={tick} width="15px" alt="tick"/> &nbsp;CCPA and LGPD Compliant</p>
                        <p className="font-14 mg-bottom-5"><img src={tick} width="15px" alt="tick"/> &nbsp;SOC 2 Type 2</p>
                        <p className="font-14 mg-bottom-5"><img src={tick} width="15px" alt="tick"/> &nbsp;Annual Cloud Penetration Testing</p>
                        <p className="font-14 mg-bottom-5"><img src={tick} width="15px" alt="tick"/> &nbsp;Annual Source Code Review</p>
                    </div>
                    <div className="column is-6 no-padding-right">
                        <p>Internal Checks and Compliances:</p>
                        <br/>
                        <p className="font-14 mg-bottom-5"><img src={tick} width="15px" alt="tick"/> &nbsp;AES 256 Data Encryption</p>
                        <p className="font-14 mg-bottom-5"><img src={tick} width="15px" alt="tick"/> &nbsp;AWS HSM for Encryption</p>
                        <p className="font-14 mg-bottom-5"><img src={tick} width="15px" alt="tick"/> &nbsp;Quarterly Risk Assessment</p>
                        <p className="font-14 mg-bottom-5"><img src={tick} width="15px" alt="tick"/> &nbsp;Quarterly Access Review</p>
                        <p className="font-14 mg-bottom-5"><img src={tick} width="15px" alt="tick"/> &nbsp;Internal Periodic VAPT</p>
                        <p className="font-14 mg-bottom-5"><img src={tick} width="15px" alt="tick"/> &nbsp;BCP/DR</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
)

export default ScaleSecurity

