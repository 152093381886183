import React, { Component } from 'react';
import GE from '../../images/ge.png';
import MMT from '../../images/mmt.png';
import JUBI from '../../images/jubilant.png';
import deep from '../../images/deep.png';
import biplob from '../../images/biplob.png';
import terri from '../../images/terri.png';
import StoryCard from '../reuseable/StoryCard';
import VideoModal from '../reuseable/VideoModal';
import { Link } from "gatsby";

export default class HomeStories extends Component {
    state = {
        reviews: [
            {
                image: GE,
                review: 'Interacting with [Amber] is done so well, it feels human, it feels natural, it feels like I am talking to someone who is listening to me.',
                by: 'Terri Bresenham',
                designation: 'President & CEO',
                video_image: terri,
                video_id: 'pwGG0eEk-L4'
            },
            {
                image: MMT,
                review: 'I don’t think we have seen or used anything like Amber. Post the recent merger with GoIbibo and RedBus, I am very excited about it.',
                by: 'Deep Kalra',
                designation: 'Chairman & Group CEO',
                video_image: deep,
                video_id: 'veGWN6VaVgE'
            },
            {
                image: JUBI,
                review: 'My personal take in this whole subject is that today, Amber is helping me prepare better for a qualitative human touch.',
                by: 'Biplob Banerjee',
                designation: 'Ex-CHRO, Jubilant Foodworks',
                video_image: biplob,
                video_id: 'LVpZF0nAoF4'
            },
        ],
        is_active: false,
        selected_video_id: ''
    }

    selectVideo = (selected_video_id) => {
        this.setState({selected_video_id, is_active: true});
    }

    onClose = () => {
        this.setState({is_active: false});
    }

    render() {
        const { reviews, is_active, selected_video_id } = this.state;
        return (
            <div className="has-text-centered bg-path-3 pd-10">
                <div className="is-hidden-tablet">
                    <br/>
                </div>
                <h3 className="is-size-3-tablet is-size-4-mobile has-text-weight-bold">Stories from Progressive CxOs</h3>
                <br/>
                <div className="columns column is-11 mar-auto has-text-left flex-wrap">
                    {reviews.map(review => (
                        <div  key={review.by} className="column is-4">
                            <StoryCard review={review} onVideoClick={this.selectVideo}/>
                        </div>
                    ))}
                </div>
                <Link to="/customers#testimonials" className="button is-link is-inverted transparent-bg is-blue-text">
                    See more Stories <i className="arrow right right-arrow"></i>
                </Link>
                <br/>
                <br/>
                {is_active ? <VideoModal video_id={selected_video_id} onClose={this.onClose} is_active={is_active}/> : null}
            </div>
        )
    }
}

