import React, { Component } from 'react';
import classnames from 'classnames';
import step1 from '../../images/step-1.png';
import step2 from '../../images/step-2.gif';
import step3 from '../../images/step-3.png';
import mobile_chat from '../../images/mobile-chat.png';
import mood_graph from '../../images/mood_graph.png';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

export default class ThreeSteps extends Component {
    state = {
        selected_step: 1
    }

    selectStep = (selected_step) => {
        this.setState({selected_step});
    }

    onNext = () => {
        const { selected_step } = this.state;
        this.setState({selected_step: selected_step + 1});
    }

    onPrevious = () => {
        const { selected_step } = this.state;
        this.setState({selected_step: selected_step - 1});
    }

    render() {
        const { selected_step } = this.state;
        return (
            <div className="has-text-centered">
                <br/>
                <h3 className="is-size-3-tablet is-size-4-mobile has-text-weight-bold">Continuously measure employee experience in 3 steps</h3>
                <br/><br/>
                <div className="columns column is-11 mar-auto has-text-left padding-20-mobile">
                    <div onClick={this.selectStep.bind(this, 0)} className={classnames('column is-4 pointer', {'bg-opacity-6': selected_step !== 0})}>
                        <p className="is-size-7 is-blue-text mg-bottom-10 has-text-weight-bold"><u>STEP 1</u></p>
                        <p className="font-13 mg-bottom-10 has-text-weight-bold">Select touchpoints & questions</p>
                        <p className="font-13">Ask the right questions at the right time based on our Employee Experience Framework™, developed through years of research by our People Scientists.</p>
                        <br className="is-hidden-tablet"/><img src={step1} width="100%" className="is-hidden-tablet" alt="step1"/> 
                    </div>
                    <div onClick={this.selectStep.bind(this, 1)} className={classnames('column is-4 pointer', {'bg-opacity-6': selected_step !== 1})}>
                        <p className="is-size-7 is-blue-text mg-bottom-10 has-text-weight-bold"><u>STEP 2</u></p>
                        <p className="font-13 mg-bottom-10 has-text-weight-bold">Connect in a personalized manner</p>
                        <p className="font-13">Equipped with sentimental analysis, Amber reaches out to employees in a personalized, timely manner, interprets sentiment, and responds empathetically, helping you understand their experience better.</p>
                        <br className="is-hidden-tablet"/><div className="has-text-centered"><img src={mobile_chat} width="65%" className="is-hidden-tablet"  alt="step2"/></div>
                    </div>
                    <div onClick={this.selectStep.bind(this, 2)} className={classnames('column is-4 pointer', {'bg-opacity-6': selected_step !== 2 && selected_step !== 3})}>
                        <p className="is-size-7 is-blue-text mg-bottom-10 has-text-weight-bold"><u>STEP 3</u></p>
                        <p className="font-13 mg-bottom-10 has-text-weight-bold">Drive Strategy with Predictive People Analytics</p>
                        <p className="font-13">Get actionable employee experience insights in real time right in your inbox. Deep dive using our dashboard to stay on top of your organisation pulse and continuously evaluate your culture.</p>
                        <br className="is-hidden-tablet pd-20"/><img src={mood_graph} width="100%" className="is-hidden-tablet"  alt="step3"/>
                        <div className="is-hidden-tablet"><br/><br/><img src={step3} width="100%"  alt="step3"/></div>
                    </div>
                </div> 
                <div className="column is-11 mar-auto no-padding-top is-hidden-mobile">
                    <button className="button is-link is-inverted is-pulled-left font-14" onClick={this.onPrevious} disabled={selected_step === 0}>
                        <i className="arrow left left-arrow"></i> &nbsp;Previous
                    </button>
                    <button className="button is-link is-inverted is-pulled-right font-14" onClick={this.onNext} disabled={selected_step === 2}>
                        Next <i className="arrow right right-arrow"></i> 
                    </button>
                </div>
                <div className="columns is-hidden-mobile">
                    <figure className="column is-10 mar-auto">
                        {selected_step === 0 ? <img src={step1} width="100%"  alt="step1"/> : null}
                        {selected_step === 1 ? <img src={step2} width="90%"  alt="step2"/> : null}
                        {/* {selected_step === 2 ? <img src={step3} width="90%"  alt="step3"/> : null}
                        {selected_step === 3 ? <div><br/><br/><img src={mood_graph} width="90%"  alt="step3"/></div> : null} */}
                        {selected_step === 2 
                            ? 
                            <Carousel showArrows={false} showStatus={false} showThumbs={false} infiniteLoop={true} autoPlay={true}>
                                <div>
                                    <img src={mood_graph} alt="mood graph"/>
                                </div>
                                <div>
                                    <img src={step3} alt="step 3"/>
                                </div>
                            </Carousel>
                            : null
                        }
                    </figure>
                </div>
                {selected_step !== 0 ? <div>
                    <br/>
                    <br/>
                    <br/>
                </div> : null}
            </div>
        )
    }
}
