import React, { Component } from 'react';
import ScheduleDemoButton from '../scheduledemo/ScheduleDemoButton';
import g2_medal from '../../images/g2_medal.svg'
import y_combinator from '../../images/y_combinator_grey.png'
export default class MeetAmber extends Component {
    render() {
        return (
                <section className="hero is-medium is-bold">
                  <div className="hero-body pd-top-4 bg-meet-amber amber-padding">
                    <div className="container">
                        <div className="is-hidden-mobile"><br/><br/></div>
                        <div className="columns">
                            <div className="column is-8">
                                <p className="is-size-5">Meet <span className="amber-name">Amber.</span></p><br/>
                                <h2 className="is-size-4-mobile is-size-2-tablet">
                                    She's an <b>employee experience bot</b> who chats with your <b>employees</b> and <b>finds <br className="is-hidden-tablet"/>those who are<br className="is-hidden-tablet"/></b>
                                    <div className="slidingVertical has-text-weight-bold">
                                        <span>disengaged.</span>
                                        <span>unhappy.</span>
                                        <span>about to leave.</span>
                                    </div>
                                </h2>
                                <br/><br/>
                                <div className="is-hidden-mobile"><ScheduleDemoButton initial_email_enabled={true}/></div>
                                <div className="is-hidden-tablet"><ScheduleDemoButton/></div>
                                <br/>
                                <div className="yc-g2-image-div">
                                   <img src={g2_medal} className="g2-img" alt="infeedo logo white"/>
                                   <img src={y_combinator} className="y-combinator-img" alt="infeedo logo white"/>
                                </div>
                                <br/>
                                <br/>
                                <br/>
                            </div>
                            <div className="column is-4">
                                {/* <img src={meetAmber}/> */}
                            </div>
                        </div>
                    </div>
                  </div>
                </section>
        )
    }
}
